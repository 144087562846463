// import React from 'react';

// const FilesModal = ({ isOpen, onClose, files }) => {
//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center ">
//       <div className="bg-white p-6 rounded-md shadow-md w-[50%]">
//         <h2 className="text-xl font-bold mb-4">Fichiers téléchargés</h2>
//         <ul className="list-disc list-inside">
//           {files.length > 0 ? (
//             files.map((file, index) => (
//               <li key={index} className="mb-2">
//                 <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
//                   {file.name}
//                 </a>
//               </li>
//             ))
//           ) : (
//             <p className="text-red-500">Aucun fichier trouvé pour ce champ.</p>
//           )}
//         </ul>
//         <button
//           className="mt-4 px-4 py-2 bg-[#006462] text-[#ffdf76] rounded-md"
//           onClick={onClose}
//         >
//           Fermer
//         </button>
//       </div>
//     </div>
//   );
// };

// export default FilesModal;

const FilesModal = ({ isOpen, onClose, files }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md shadow-md w-[50%]">
        <h2 className="text-xl font-bold mb-4">Fichiers téléchargés</h2>
        <ul className="list-disc list-inside">
          {files.length > 0 ? (
            files.map((file, index) => (
              <li key={index} className="mb-2">
                <a
                  href={file.url} // Full URL to the file
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {file.name} {/* Display just the file name */}
                </a>
              </li>
            ))
          ) : (
            <p className="text-red-500">Aucun fichier trouvé pour ce champ.</p>
          )}
        </ul>
        <button
          className="mt-4 px-4 py-2 bg-[#006462] text-[#ffdf76] rounded-md"
          onClick={onClose}
        >
          Fermer
        </button>
      </div>
    </div>
  );
};

export default FilesModal;
