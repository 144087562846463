import React, { useState } from 'react';

const UploadModal = ({ isOpen, onClose, onSave }) => {
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    // Convertir FileList en tableau
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const handleSave = () => {
    console.log(files); 
    onSave(files);
    onClose();
};

  if (!isOpen) return null;

  return (
    <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="modal-content bg-white p-6 rounded-lg">
        <h2 className="text-lg font-bold mb-4">Télécharger des fichiers</h2>
        <input type="file" multiple onChange={handleFileChange} className="mb-4" />
        <div className="flex justify-end space-x-4">
          <button onClick={handleSave} className="bg-[#006462] text-[#ffdf76] py-2 px-4 rounded ">Enregistrer</button>
          <button onClick={onClose} className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700">Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
