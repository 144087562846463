

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../demandeSlice';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Modal from '../components/modal';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import icon1 from '../img/icone-texte-jaune.png';


import axios from 'axios';


const Demande2 = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.demandes.formData);
  const location = useLocation();
  const navigate = useNavigate();


  const { kbisEntrepriseFile, mandatSpecialFile, documentProprieteFile } = location.state || {};
  const [copieFactureFile, setcopieFactureFile] = useState(null);
  const [proprieteFile, setproprieteFile] = useState(null);
  const [dessinsSchemaPhotoFile, setdessinsSchemaPhotoFile] = useState(null);
  const [photosAutresDocsFile, setphotosAutresDocsFile] = useState(null);

  const [files, setFiles] = useState({
    copieFacture:  [],
    propriete:  [],
    dessinsSchemaPhoto: [], 
    photosAutresDocs: [],    
    mandat_special: mandatSpecialFile || [],
    kbis_entreprise: kbisEntrepriseFile || [],
    document_propriete: documentProprieteFile || [],
    cin: null,
});


  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [signupData, setSignupData] = useState({
    entreprise: '',
    username: '',
    Adresse: '',
    Complementd_adresse: '',
    CP: '',
    Ville: '',
    email: '',
    tele: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let processedValue = value;
  
    if (type === 'checkbox') {
      processedValue = checked ? 1 : 0; 
    }
  
    if (modalContent === 'signup') {
      setSignupData((prevSignupData) => ({
        ...prevSignupData,
        [name]: processedValue, 
      }));
    } else {

      if (name === 'installation') {
        dispatch(updateFormData({
          [name]: value,
          installation_photovoltaique: value === 'oui' ? 1 : 0, 
        }));
      } else {
        dispatch(updateFormData({
          [name]: processedValue,
        }));
      }
    }
  };
  
  const handleBlur = () => {
    if (!files.cin) {
      setCinError("Le champ CIN est obligatoire.");
    } else {
      setCinError(""); 
    }
  };

  const [cinError, setCinError] = useState(""); 

  const handleNext = () => {
    
    navigate('/confirmation', {
      state: {
        kbisEntrepriseFile: files.kbis_entreprise,
        mandatSpecialFile: files.mandat_special,
        documentProprieteFile: files.document_propriete,
        copieFactureFile: files.copieFacture,
        proprieteFile: files.propriete,
        dessinsSchemaPhotoFile: files.dessinsSchemaPhoto,
        photosAutresDocsFile: files.photosAutresDocs,
        cinFile: files.cin,
        formData: {
          
          ...formData, 
        },
      },
    });
  };
  
 
  const handleFileChange = (event, field, multiple = false) => {
    const { name, files } = event.target;
    const selectedFiles = multiple ? Array.from(files) : files[0];
  
    console.log(`Selected files for ${name}:`, selectedFiles);
  
    setFiles((prevFiles) => ({
      ...prevFiles,
      [field]: multiple ? [...(prevFiles[field] || []), ...selectedFiles] : selectedFiles,
    }));
  };



  const handleModalOk = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.autoConsommation) {
      if (!files.cin) {
        alert('Le champ CIN est requis.');
        return;
      }
      if (!formData.nom_beneficiaire) {
        alert('Le champ Nom du bénéficiaire est requis.');
        return;
      }
    }
  
    const token = localStorage.getItem('token');
    if (!token) {
      setModalContent('signup');
      setShowModal(true);
    } else {
      await submitDemande(token);
    }
  };
  


// const submitDemande = async (token) => {
//   const formDataToSend = new FormData();

//   if (files.cin) {
//       formDataToSend.append('cin', files.cin);
//   }
  
//   if (Array.isArray(files.kbis_entreprise)) {
//       files.kbis_entreprise.forEach((file) => {
//           formDataToSend.append('kbis_entreprise[]', file); 
//               });
//   }

//   if (Array.isArray(files.mandat_special)) {
//       files.mandat_special.forEach((file) => {
//           formDataToSend.append('mandat_special[]', file); 
//       });
//   }
//   if (Array.isArray(files.document_propriete)) {
//     files.document_propriete.forEach((file) => {
//         formDataToSend.append('document_propriete[]', file); 
//     });
// }

//   if (files.copieFacture) {
//       formDataToSend.append('copie_facture[]', files.copieFacture); 
//   }

//   if (files.propriete) {
//       formDataToSend.append('propriete[]', files.propriete); 
//   }

//   if (files.dessinsSchemaPhoto.length > 0) {
//       files.dessinsSchemaPhoto.forEach((file) => formDataToSend.append('dessins_schema_photo[]', file));
//   }

//   if (files.photosAutresDocs.length > 0) {
//       files.photosAutresDocs.forEach((file) => formDataToSend.append('photos_autres_docs[]', file));
//   }

//   const prestationsArray = Array.isArray(formData.prestations_souhaitees)
//       ? formData.prestations_souhaitees
//       : formData.prestations_souhaitees.split(',').map(item => item.trim());

//   prestationsArray.forEach((item) => {
//       formDataToSend.append('prestations_souhaitees[]', item); 
//   });

//   const branchementCompteur = [];
//   if (formData.monophasé) branchementCompteur.push('Monophasé');
//   if (formData.triphasé) branchementCompteur.push('Triphasé');

//   formDataToSend.append('branchement_compteur', branchementCompteur.join(','));
//   formDataToSend.append('destination_batiment', formData.destination_batiment || '');
//   formDataToSend.append('installateur_email', formData.installateur_email || '');
//   formDataToSend.append('installateur_telephone', formData.installateur_telephone || '');
//   formDataToSend.append('marques_modeles_panneaux_onduleurs', formData.marques_modeles_panneaux_onduleurs || '');
//   formDataToSend.append('puissance_prevue', formData.puissance_prevue || '');
//   formDataToSend.append('type_client', formData.type_client || '');
//   formDataToSend.append('type_installation', formData.type_installation || '');
//   formDataToSend.append('type_production', formData.type_production || '');
//   formDataToSend.append('nom_projet', formData.nom_projet || '');
//   formDataToSend.append('nom', formData.nom || '');
//   formDataToSend.append('prenom', formData.prenom || '');
//   formDataToSend.append('puissance_ancienne_installation', formData.puissance_ancienne_installation || '');
//   formDataToSend.append('ref_cadastrale_parcelle', formData.ref_cadastrale_parcelle || '');
//   formDataToSend.append('ref_cadastrale_section', formData.ref_cadastrale_section || '');
//   formDataToSend.append('coordonnees_gps', formData.coordonnees_gps || '');






//   formDataToSend.append('installation_photovoltaique', formData.installation_photovoltaique ? 1 : 0);
//   formDataToSend.append('autoconsommation', formData.autoconsommation ? 1 : 0);

//   console.log("Form data before submission:", Array.from(formDataToSend.entries()));

//   try {
//       const response = await axios.post('http://localhost:8000/api/demande/add', formDataToSend, {
//         // const response = await axios.post('https://www.lampenergie.fr/admin/public/api/demande/add', formDataToSend, {

//           headers: {
//               Authorization: `Bearer ${token}`,
//               'Content-Type': 'multipart/form-data',
//           },
//       });
//       console.log("Response:", response.data);
//        setModalContent('success');
//        setShowModal(true);
       
   
 
//    } catch (error) {
//        if (error.response) {
//            console.error("Error submitting demande:", error.response.data);
//        } else {
//            console.error("Error submitting demande:", error.message);
//        }
//        setModalContent('error');
//        setShowModal(true);
//    }
//  };
 

 const submitDemande = async (token) => {
  const formDataToSend = new FormData();

  // Append files (if available)
  if (files.cin) {
    formDataToSend.append('cin', files.cin);
  }

  if (Array.isArray(files.kbis_entreprise)) {
    files.kbis_entreprise.forEach((file) => {
      formDataToSend.append('kbis_entreprise[]', file);
    });
  }

  if (Array.isArray(files.mandat_special)) {
    files.mandat_special.forEach((file) => {
      formDataToSend.append('mandat_special[]', file);
    });
  }

  if (Array.isArray(files.document_propriete)) {
    files.document_propriete.forEach((file) => {
      formDataToSend.append('document_propriete[]', file);
    });
  }

  if (files.copieFacture) {
    formDataToSend.append('copie_facture[]', files.copieFacture);
  }

  if (files.propriete) {
    formDataToSend.append('propriete[]', files.propriete);
  }

  if (files.dessinsSchemaPhoto.length > 0) {
    files.dessinsSchemaPhoto.forEach((file) =>
      formDataToSend.append('dessins_schema_photo[]', file)
    );
  }

  if (files.photosAutresDocs.length > 0) {
    files.photosAutresDocs.forEach((file) =>
      formDataToSend.append('photos_autres_docs[]', file)
    );
  }

  // Append other fields from formData
  formDataToSend.append('installateur_email', formData.installateur_email || '');
  formDataToSend.append('installateur_telephone', formData.installateur_telephone || '');
  formDataToSend.append('type_client', formData.type_client || '');
  formDataToSend.append('type_installation', formData.type_installation || '');
  formDataToSend.append('puissance_prevue', formData.puissance_prevue || '');
  formDataToSend.append('type_production', formData.type_production || '');

  // Add prestations_souhaitees array
  const prestationsArray = Array.isArray(formData.prestations_souhaitees)
    ? formData.prestations_souhaitees
    : formData.prestations_souhaitees.split(',').map((item) => item.trim());

  prestationsArray.forEach((item) => {
    formDataToSend.append('prestations_souhaitees[]', item);
  });

  // Other fields
  formDataToSend.append('marques_modeles_panneaux_onduleurs', formData.marques_modeles_panneaux_onduleurs || '');
  formDataToSend.append('destination_batiment', formData.destination_batiment || '');

  // Meter connection (branchement_compteur)
  const branchementCompteur = [];
  if (formData.monophasé) branchementCompteur.push('Monophasé');
  if (formData.triphasé) branchementCompteur.push('Triphasé');
  formDataToSend.append('branchement_compteur', branchementCompteur.join(','));

  // Add other project details
  formDataToSend.append('nom_projet', formData.nom_projet || '');
  formDataToSend.append('ref_cadastrale_section', formData.ref_cadastrale_section || '');
  formDataToSend.append('ref_cadastrale_parcelle', formData.ref_cadastrale_parcelle || '');
  formDataToSend.append('surface_totale_parcelle', formData.surface_totale_parcelle || '');
  formDataToSend.append('coordonnees_gps', formData.coordonnees_gps || '');
  formDataToSend.append('date_mes', formData.date_mes || '');
  formDataToSend.append('numero_crae', formData.numero_crae || '');
  formDataToSend.append('marque_ancien_onduleur', formData.marque_ancien_onduleur || '');
  formDataToSend.append('modele_ancien_onduleur', formData.modele_ancien_onduleur || '');
  formDataToSend.append('puissance_ancienne_installation', formData.puissance_ancienne_installation || '');

  // Client personal info
  formDataToSend.append('nom', formData.nom || '');
  formDataToSend.append('prenom', formData.prenom || '');
  formDataToSend.append('date_de_naissance', formData.date_de_naissance || '');
  formDataToSend.append('lieu_de_naissance', formData.lieu_de_naissance || '');
  formDataToSend.append('pays', formData.pays || '');
  formDataToSend.append('telephone_fixe', formData.telephone_fixe || '');
  formDataToSend.append('telephone_mobile', formData.telephone_mobile || '');
  formDataToSend.append('email', formData.email || '');

  // Production address
  formDataToSend.append('cp_production', formData.cp_production || '');
  formDataToSend.append('ville_production', formData.ville_production || '');
  formDataToSend.append('adresse_production', formData.adresse_production || '');
  formDataToSend.append('complement_adresse_production', formData.complement_adresse_production || '');

  // Flags and status
  formDataToSend.append('installation_photovoltaique', formData.installation_photovoltaique ? 1 : 0);
  formDataToSend.append('autoconsommation', formData.autoconsommation ? 1 : 0);
  formDataToSend.append('nom_beneficiaire', formData.nom_beneficiaire || '');
  formDataToSend.append('dossier_traite', formData.dossier_traite || '0%');
  formDataToSend.append('demande_statut', formData.demande_statut || 'non traiter');

  // Submit the form
  try {
    const response = await axios.post('http://www.lampenergie.fr/admin/public/api/demande/add', formDataToSend, {
        // const response = await axios.post('http://localhost:8000/api/demande/add', formDataToSend, {

      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log("Response:", response.data);
    setModalContent('success');
    setShowModal(true);
  } catch (error) {
    console.error("Error submitting demande:", error.response ? error.response.data : error.message);
    setModalContent('error');
    setShowModal(true);
  }
};


const handleSignupSubmit = async (e) => {
  e.preventDefault();

  try {
    // const response = await fetch('http://localhost:8000/api/register', {
      const response = await fetch('https://www.lampenergie.fr/admin/public/api/register', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entreprise: signupData.entreprise,
        username: signupData.username,
        Adresse: signupData.Adresse,
        Complementd_adresse: signupData.Complementd_adresse,
        CP: signupData.CP,
        Ville: signupData.Ville,
        email: signupData.email,
        tele: signupData.tele,
        password: signupData.password,
        role: 'installateur',
      }),
    });

    if (!response.ok) {
      const contentType = response.headers.get('content-type');
      let errorData;

      if (contentType && contentType.includes('application/json')) {
        errorData = await response.json();
      } else {
        errorData = { message: 'Unexpected response format from server' };
      }

      console.error('Signup errors:', errorData.errors || errorData.message || 'Unknown error occurred');
      setModalContent('error');
    } else {
      const data = await response.json();
      localStorage.setItem('token', data.token); // Store the token
      await submitDemande(data.token); // Submit the demand form with the new token
      setModalContent('success'); // Show success modal
    }
  } catch (error) {
    console.error('Error:', error.message || error);
    setModalContent('error');
  }
};


  const handleModalClose = () => {
    setShowModal(false);

    dispatch(updateFormData({
        ref_cadastrale_section: '',
        ref_cadastrale_parcelle: '',
        surface_totale_parcelle: '',
        coordonnees_gps: '',
        monophasé: false,
        triphasé: false,
        installation: 'non',
        nom_projet:'',
        date_mes: '',
        numero_crae: '',
        marque_ancien_onduleur: '',
        modele_ancien_onduleur: '',
        puissance_ancienne_installation: '',
        nom: '',
        prenom: '',
        date_de_naissance: '',
        lieu_de_naissance: '',
        pays: '',
        adresse: '',
        complement_adresse: '',
        cp: '',
        ville: '',
        telephone_fixe: '',
        telephone_mobile: '',
        email: '',
        autoconsommation: false,
        nom_beneficiaire: '',
    }));

    setFiles({
        copieFacture: null,
        propriete: null,
        dessinsSchemaPhoto: [],
        photosAutresDocs: [],
        mandat_special: null,
        kbis_entreprise: null,
        document_propriete: null,
        cin: null,
    });
  };

  const renderModalContent = () => {
    switch (modalContent) {
      case 'success':
        return (
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Enregistrement réussi</h2>
            <p>Votre demande a été enregistrée avec succès.</p>
            <button
              className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
              onClick={handleModalClose}
            >
              Fermer
            </button>
          </div>
        );
      case 'error':
        return (
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Erreur</h2>
            <p>Une erreur est survenue lors de l'enregistrement. Veuillez vérifier les champs et réessayer.</p>
            <button
              className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
              onClick={() => setShowModal(false)}
            >
              Fermer
            </button>
          </div>
        );
      case 'signup':
        return (
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Connexion requise</h2>
            <p>Vous devez être connecté pour soumettre ce formulaire. Veuillez vous inscrire ou vous connecter.</p>
            <form onSubmit={handleSignupSubmit}>
              <input
                type="text"
                name="entreprise"
                placeholder="Entreprise"
                value={signupData.entreprise}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="username"
                placeholder="Nom du dirigeant"
                value={signupData.username}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="Adresse"
                placeholder="Adresse"
                value={signupData.Adresse}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="Complementd_adresse"
                placeholder="Complément d’adresse"
                value={signupData.Complementd_adresse}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="number"
                name="CP"
                placeholder="CP"
                value={signupData.CP}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="Ville"
                placeholder="Ville"
                value={signupData.Ville}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={signupData.email}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="tele"
                placeholder="Téléphone"
                value={signupData.tele}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="password"
                name="password"
                placeholder="Mot de passe"
                value={signupData.password}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <button
                type="submit"
                className="mt-4 py-2 px-4 bg-[#006462] text-white rounded w-full"
              >
                S'inscrire / Se connecter
              </button>
            </form>
          </div>
        );
      default:
        return null;
    }
  };
  

  return (
    <div>
      <Header />
      <div className="bg-white p-4 lg:mx-12">
        <form onSubmit={handleSubmit} className="space-y-6">
          <h2 className="2xl:text-3xl text-[25px] font-medium text-[#616565]">Références cadastrales du terrain :</h2>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-0 px-2">
            <div className="flex flex-col lg:flex-row items-center gap-2">
              <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[30%] 2xl:text-end md:w-[100%]">
                N° de section :
              </label>
              <input
                type="number"
                name="ref_cadastrale_section"
                value={formData.ref_cadastrale_section || ''}
                onChange={handleChange}
                className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[50%] md:w-[100%]"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-2 ">
              <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[30%] 2xl:text-end md:w-[100%]">
                N° de parcelle :
              </label>
              <input
                type="number"
                name="ref_cadastrale_parcelle"
                value={formData.ref_cadastrale_parcelle || ''}
                onChange={handleChange}
                className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[50%] md:w-[100%]"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-center gap-2 xl:gap-1">
              <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[80%] 2xl:text-end">
                Surface totale :
              </label>
              <input
                type="number"
                name="surface_totale_parcelle"
                value={formData.surface_totale_parcelle || ''}
                onChange={handleChange}
                className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[60%]"
              />
              <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[40%]">
                M<sup>2</sup>
              </label>
            </div>
          </div>

          <div className="flex flex-wrap sm:flex-row w-full">
            <label className="w-full text-[#1c7573] font-medium text-[16px] 2xl:w-[20%] xl:w-[20%] lg:w-[30%] md:w-[30%]">
              Coordonnées GPS ou Google Earth :
            </label>
            <input
              type="text"
              name="coordonnees_gps"
              value={formData.coordonnees_gps || ''}
              onChange={handleChange}
              className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[70%] md:w-[70%] 2xl:w-[80%] xl:w-[80%]"
            />
          </div>

          <h2 className="text-[25px] 2xl:text-2xl font-medium text-[#616565]">
            Éléments de facture elec du client producteur (ou copie de la facture complète)
          </h2>
          <div className="flex flex-col sm:flex-row items-center space-x-4 sm:ml-">
            <div className="ml-5">
              <label className="text-[#1c7573] text-lg font-semibold md:text-[1.12rem]">
                Copie facture, photos, disjoncteur et compteur :
              </label>
            </div>

               {/* Button to show modal */}
            <div>
              <div
                className="py-3 px-8 bg-[#006462] text-[#ffdf76] font-normal text-2xl rounded-none flex items-center cursor-pointer lg:w-full md:w-[100%] w-[70%]"
                onClick={() => setShowModal(true)}
              >
                Télécharger des fichiers
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row items-baseline space-x-4 ml-5">
            <label className="text-[#1c7573] font-medium">Branchement (compteur)* :</label>
            <label className="flex items-center font-semibold text-[#1c7573] space-x-2">
              <span>Monophasé</span>
              <input
                type="checkbox"
                name="monophasé"
                checked={formData.monophasé || false}
                onChange={handleChange}
                className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </label>
            <label className="flex items-center font-semibold text-[#1c7573] space-x-2">
              <span>Triphasé</span>
              <input
                type="checkbox"
                name="triphasé"
                checked={formData.triphasé || false}
                onChange={handleChange}
                className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
              />
            </label>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ml-5">
            <div className="flex flex-col sm:flex-row items-left space-x-2">
              <label className="text-[#ff1c1e] italic font-medium">
                Disposez-vous déjà d'une installation photovoltaïque ?
              </label>
              <label className="flex items-center text-[#ff1c1e] italic space-x-2">
                <input
                  type="radio"
                  name="installation"
                  value="non"
                  checked={formData.installation === 'non'}
                  onChange={handleChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <span>NON</span>
              </label>
              <label className="flex items-center text-[#ff1c1e] italic space-x-2">
                <input
                  type="radio"
                  name="installation"
                  value="oui"
                  checked={formData.installation === 'oui'}
                  onChange={handleChange}
                  className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                />
                <span>OUI</span>
              </label>
            </div>
          </div>

          {formData.installation === 'oui' && (
  <>
    {/* Champ obligatoire Nom Projet */}
    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Nom de Chantier :*</label>
      <input
        type="text"
       name="nom_projet"
  placeholder="Project-123456"
  value={formData.nom_projet || ''}
  onChange={handleChange}
  pattern="[a-zA-Z]+-\d{6}"
  required
  className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[50%]"
/>

    </div>

    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Nom Complet de CLient :*</label>
      <input
        type="text"
       name="nom"

       value={formData.nom|| ''}
    onChange={handleChange}
  required
  className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[50%]"
/>

    </div>


    {/* Champ Date de MES */}
    <div className="flex flex-col sm:flex-row items-left space-x-1 italic font-medium ml-5">
      <div className="flex flex-col sm:flex-row items-center space-x-1">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Date de MES :</label>
        <input
          type="date"
          name="date_mes"
          placeholder='A/M/J'
          value={formData.date_mes || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[40%]"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center space-x-1 w-full">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">N° de CRAE : 0000</label>
        <input
          type="number"
          name="numero_crae"
          value={formData.numero_crae || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[15%]"
        />
      </div>
    </div>

    {/* Autres champs */}
    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <div className="flex flex-col sm:flex-row items-center space-x-2">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Marque de l'ancien onduleur :</label>
        <input
          type="text"
          name="marque_ancien_onduleur"
          value={formData.marque_ancien_onduleur || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[43%]"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center space-x-2">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Modèle :</label>
        <input
          type="text"
          name="modele_ancien_onduleur"
          value={formData.modele_ancien_onduleur || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[62%]"
        />
      </div>
    </div>

    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">
        Puissance de l'ancienne installation de production (kWc) :
      </label>
      <input
        type="number"
        placeholder='exp:20.9'
        name="puissance_ancienne_installation"
        value={formData.puissance_ancienne_installation || ''}
        onChange={handleChange}
        className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[16%]"
      />
    </div>
  </>
)}


          {formData.installation === 'non' && (
            <>
              <div className="mb-4">
                <div className="flex flex-col md:flex-row items-left md:items-center">
                  <h2 className="text-[24px] 2xl:text-3xl font-medium text-[#616565]">
                    Informations et coordonnées complètes du client/producteur :
                  </h2>
                  {/* <div className="mt-4 md:mt-0 md:ml-4 flex items-center">
                    <input
                      type="checkbox"
                      name="autoconsommation"
                      checked={formData.autoconsommation || false}
                      onChange={handleChange}
                      className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
                    />
                    <label className="text-[#1c7573] font-medium text-[16px]">AUTOCONSOMMATION</label>
                  </div> */}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 ml-5">
              <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom de Chantier :*</label>
                  <input
                    type="text"
                    name="nom_projet"
                    placeholder="Project-123456"
                    value={formData.nom_projet || ''}
                    onChange={handleChange}
                    pattern="[a-zA-Z]+-\d{6}"
                    required
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom :*</label>
                  <input
                    type="text"
                    name="nom"
                    value={formData.nom || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                  <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Prénom :*</label>
                  <input
                    type="text"
                    name="prenom"
                    value={formData.prenom || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Date de naissance :*</label>
                  <input
                    type="date"
                    placeholder='A/M/J'
                    name="date_de_naissance"
                    value={formData.date_de_naissance || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[18%]"
                  />
                  <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Lieu de naissance :*</label>
                  <input
                    type="text"
                    name="lieu_de_naissance"
                    value={formData.lieu_de_naissance || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Pays :*</label>
                  <input
                    type="text"
                    name="pays"
                    value={formData.pays || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Adresse :*</label>
                  <input
                    type="text"
                    name="adresse"
                    value={formData.adresse || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[57%]"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Complément d'adresse :</label>
                  <input
                    type="text"
                    name="complement_adresse"
                    value={formData.complement_adresse || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[49.5%]"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">CP :*</label>
                  <input
                    type="number"
                    name="cp"
                    value={formData.cp || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[18%]"
                  />
                  <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Ville :*</label>
                  <input
                    type="text"
                    name="ville"
                    value={formData.ville || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[45.5%]"
                  />
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1">
                  <div className="flex flex-col sm:flex-row items-center space-x-1 gap-4">
                    <label className="w-full sm:w-[70%] text-[#1c7573] font-medium text-[16px]">Téléphone fixe :</label>
                    <input
                      type="text"
                      name="telephone_fixe"
                      value={formData.telephone_fixe || ''}
                      onChange={handleChange}
                      className="px-2 py-2 border border-gray-300 rounded-lg w-full"
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row items-left space-x-1">
                    <label className="w-full sm:w-[40%] text-[#1c7573] font-medium text-[16px]">Mobile :*</label>
                    <input
                      type="text"
                      name="telephone_mobile"
                      value={formData.telephone_mobile || ''}
                      onChange={handleChange}
                      className="px-2 py-2 border border-gray-300 rounded-lg w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                  <label className="w-auto text-[#1c7573] font-medium text-[16px]">Mail :*</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email || ''}
                    onChange={handleChange}
                    className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[60%]"
                  />
                </div>

                {/* {formData.autoconsommation === 1 && (
                  <>
                    <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                      <label className="w-auto text-[#1c7573] font-medium text-[16px]">CIN :*</label>
                      <input
                           type="file"
                           name="cin"
                           id="cin"
                           accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
                           onChange={(e) => handleFileChange(e, 'cin')}
                           className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                           required={formData.autoconsommation}
                           />
                    </div>
                    <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
                      <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom du bénéficiaire :*</label>
                      <input
                        type="text"
                        name="nom_beneficiaire"
                        value={formData.nom_beneficiaire || ''}
                        onChange={handleChange}
                        className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[60%]"
                        required={formData.autoconsommation}
                      />
                    </div>
                  </>
                )} */}
              </div>
            </>
          )}

          {/* <div className="flex justify-center">
            <button
              type="submit"
              className="w-full md:w-[26%] py-3 sm:py-3 md:py-4 lg:py-4 xl:py-4 2xl:py-6 px-3 bg-[#006462] text-[#ffdf76] font-normal text-2xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-4xl rounded-3xl"
              style={{ borderRadius: '50px' }}
            >
              ENREGISTRER
            </button>
          </div> */}

         
<div className="flex justify-between mt-6">
  <button
    type="button"
    onClick={() => navigate(-1)} // Navigates back to the previous page
    className="py-2 px-8 bg-[#006462] text-[#ffdf76] font-normal text-xl rounded-full flex items-center"
    style={{ borderRadius: '50px' }}
  >
    RETOUR
  </button>

  <button
    type="button"
    className="py-2 px-8 bg-[#006462] text-[#ffdf76] font-normal text-xl rounded-full flex items-center"
    onClick={handleNext}
  >
    SUIVANT
    <img src={icon1} alt="icon" className="inline-block w-8 h-8 align-middle ml-2" />
  </button>
</div>


        </form>
      </div>
      {showModal && modalContent === '' && (
    <Modal onClose={() => setShowModal(false)}>
        <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Télécharger des fichiers</h2>

            <div className="mb-4">
                <label htmlFor="copie_facture" className="block mb-2 text-[#ff1c1e]">Copie facture électricité :*</label>
                <input
                    type="file"
                    name="copie_facture"
                    onChange={(e) => handleFileChange(e, 'copieFacture')} 
                    id="copie_facture"
                    className="block w-full"
                />
            </div>
            <div className="mb-4">
                      <label htmlFor="cin" className="block mb-2 text-[#ff1c1e]">Pièce d'identité:*</label>
                      <input
                           type="file"
                           name="cin"
                           id="cin"
                           accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
                           onChange={(e) => handleFileChange(e, 'cin')}
                           className="block w-full"
                          
                           />
                    </div>
            <div className="mb-4">
                <label htmlFor="propriete" className="block mb-2 text-[#ff1c1e]">Propriété :*</label>
                <input
                    type="file"
                    name="propriete"
                    onChange={(e) => handleFileChange(e, 'propriete')}
                    id="propriete"
                    className="block w-full"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="multi-files" className="block mb-2 text-[#ff1c1e]">Implantation des panneaux en toiture :*</label>
                <input
                    type="file"
                    name="dessins_schema_photo"
                    multiple
                    onChange={(e) => handleFileChange(e, 'dessinsSchemaPhoto', true)}
                    id="dessins_schema_photo"
                    className="block w-full"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="photos-docs" className="block mb-2 text-[#ff1c1e]">photos comptage (disj+linky) :*</label>
                <input
                    type="file"
                    name="photos_autres_docs"
                    multiple
                    onChange={(e) => handleFileChange(e, 'photosAutresDocs', true)}
                    id="photos_autres_docs"
                    className="block w-full"
                />
            </div>

            {Object.values(files).some(file => file && (Array.isArray(file) ? file.length > 0 : true)) && (
                <div>
                    <h3>Fichiers sélectionnés :</h3>
                    <ul>
                        {files.copieFacture && <li>{files.copieFacture.name}</li>}
                        {files.propriete && <li>{files.propriete.name}</li>}
                        {files.dessinsSchemaPhoto.map((file, index) => (
                            <li key={index}>{file.name}</li>
                        ))}
                        {files.photosAutresDocs.map((file, index) => (
                            <li key={index}>{file.name}</li>
                        ))}
                    </ul>
                </div>
            )}

            <div className="mt-4 flex justify-between">
                <button
                    className="py-2 px-4 bg-[#006462] text-white rounded"
                    onClick={handleModalOk}
                >
                    Ok
                </button>
            </div>
        </div>
    </Modal>
)}

{/* Other modal cases */}
{showModal && modalContent !== '' && (
    <Modal onClose={() => setShowModal(false)}>
        {renderModalContent()}
    </Modal>
)}


      <Footer />
    </div>
  );
};

export default Demande2;

