import React from 'react';

const StyledButton = ({ type = 'button', onClick, children }) => {
  return (
    <button
    type={type}
    onClick={onClick}
    className="w-[50%] sm:w-[60%] md:w-[42%] lg:w-[44%] xl:w-[40%] 2xl:w-[40%] 
               py-4 sm:py-2 md:py-2 lg:py-8 xl:py-8 2xl:py-8 bg-[#006462] text-[#ffdf76] font-semibold 
               text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl 
               rounded-full"
  >
    {children}
  </button>
  
  
  
  );
};

export default StyledButton;
