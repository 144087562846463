import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';


const DemandeDetail = () => {
  const { demandeId } = useParams();
  const [demandeData, setDemandeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAutreInput, setShowAutreInput] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchDemandeDetails = async () => {
      try {
        // const response = await fetch(`http://localhost:8000/api/demandes/${demandeId}`, {
          const response = await fetch(`https://www.lampenergie.fr/admin/public/api/demandes/${demandeId}`, {

          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setDemandeData(data);
          setShowAutreInput(data.prestations_souhaitees.includes('Autre(s)'));
        } else {
          console.error('Erreur lors de la récupération des détails de la demande.');
        }
      } catch (error) {
        console.error('Erreur lors du fetch des détails de la demande :', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDemandeDetails();
  }, [demandeId]);

  if (loading) {
    return <p>Chargement des détails de la demande...</p>;
  }

  if (!demandeData) {
    return <p>Aucune demande trouvée.</p>;
  }

  const handleCheckboxChange = (event) => {
    const { name, checked, value } = event.target;
    const newValue = checked
      ? [...(demandeData[name] || []), value]
      : demandeData[name].filter((v) => v !== value);

    setDemandeData({ ...demandeData, [name]: newValue });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setDemandeData({ ...demandeData, [name]: files[0] });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDemandeData({ ...demandeData, [name]: value });
  };

  return (
    <div className="w-full h-full overflow-x-hidden">
      <Header />
      <div className="flex flex-col bg-white py-6 px-4 sm:px-6 md:px-12 lg:px-24 xl:px-24 2xl:px-24">
      <p className="self-start text-lg font-medium text-[#616565] md:text-2xl lg:text-3xl">
  Formulaire de demande d'étude : {demandeData.nom || demandeData.nom_projet}
</p>

       

        {/* Client Type Selection */}
        <div className="flex justify-center flex-col md:flex-row items-center mt-4 mb-4">
          <div className="flex items-center mr-4">
            <input
              type="checkbox"
              id="particulier"
              name="type_client"
              checked={demandeData.type_client === 'Particulier'}
              readOnly
              className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
            />
            <label htmlFor="particulier" className="text-[#070707] font-bold text-[16px]">
              Formulaire Particulier
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="professionnel"
              name="type_client"
              checked={demandeData.type_client === 'Professionnel'}
              readOnly
              className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
            />
            <label htmlFor="professionnel" className="text-[#070707] font-bold text-[16px]">
              Formulaire Professionnel
            </label>
          </div>
        </div>

       {/* Formulaire Particulier */}
       {demandeData.type_client === 'Particulier' && (
          <form className="w-full space-y-6 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="installateur_email" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                E-mail installateur: *
              </label>
              <input
                type="email"
                name="installateur_email"
                id="installateur_email"
                value={demandeData.installateur_email || ''}
                readOnly
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
              />
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="installateur_telephone" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Téléphone installateur: *
              </label>
              <input
                type="text"
                name="installateur_telephone"
                id="installateur_telephone"
                value={demandeData.installateur_telephone || ''}
                readOnly
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
              />
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
  <label className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
    Prestations souhaitées: *
  </label>
  <div className="flex flex-col md:flex-row">
    <div className="flex items-center mr-4">
      <input
        type="checkbox"
        id="declaration_prealable"
        name="prestations_souhaitees[]"
        value="Déclaration Préalable"
        checked={demandeData.prestations_souhaitees?.includes('Déclaration Préalable')}
        readOnly
        disabled
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="declaration_prealable" className="text-[#070707] font-semibold text-[16px]">
        Déclaration Préalable
      </label>
    </div>
    <div className="flex items-center mr-4">
      <input
        type="checkbox"
        id="enedis"
        name="prestations_souhaitees[]"
        value="ENEDIS"
        checked={demandeData.prestations_souhaitees?.includes('ENEDIS')}
        readOnly
        disabled
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="enedis" className="text-[#070707] font-semibold text-[16px]">
        ENEDIS
      </label>
    </div>
    <div className="flex items-center mr-4">
      <input
        type="checkbox"
        id="consuel"
        name="prestations_souhaitees[]"
        value="CONSUEL"
        checked={demandeData.prestations_souhaitees?.includes('CONSUEL')}
        readOnly
        disabled
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="consuel" className="text-[#070707] font-semibold text-[16px]">
        CONSUEL
      </label>
    </div>
    <div className="flex items-center">
      <input
        type="checkbox"
        id="autre"
        name="prestations_souhaitees[]"
        value="Autre(s)"
        checked={demandeData.prestations_souhaitees?.includes('Autre(s)')}
        readOnly
        disabled
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="autre" className="text-[#070707] font-semibold text-[16px]">
        Autre(s)
      </label>
      {showAutreInput && (
        <input
          type="text"
          name="autrePrecision"
          placeholder="Préciser"
          value={demandeData.autrePrecision || ''}
          readOnly
          className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
        />
      )}
    </div>
  </div>
</div>

          </form>
        )}

        {/* Formulaire Professionnel */}
        {demandeData.type_client === 'Professionnel' && (
          <form className="w-full space-y-6 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="installateur_email" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                E-mail installateur: *
              </label>
              <input
                type="email"
                name="installateur_email"
                id="installateur_email"
                value={demandeData.installateur_email || ''}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="installateur_telephone" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Téléphone installateur: *
              </label>
              <input
                type="text"
                name="installateur_telephone"
                id="installateur_telephone"
                value={demandeData.installateur_telephone || ''}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="nom_entreprise" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Nom de l'entreprise installatrice: *
              </label>
              <input
                type="text"
                name="nom_entreprise"
                id="nom_entreprise"
                value={demandeData.nom_entreprise || ''}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
              <label htmlFor="numero_siret" className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
                Numéro SIRET de l'entreprise: *
              </label>
              <input
                type="text"
                name="numero_siret"
                id="numero_siret"
                value={demandeData.numero_siret || ''}
                onChange={handleChange}
                className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="flex flex-col md:flex-row gap-4 items-center mb-4">
  <label className="w-full md:w-1/4 text-[#1c7573] font-medium text-[16px]">
    Prestations souhaitées: *
  </label>
  <div className="flex flex-col md:flex-row">
    <div className="flex items-center mr-4">
      <input
        type="checkbox"
        id="declaration_prealable"
        name="prestations_souhaitees[]"
        value="Déclaration Préalable"
        checked={demandeData.prestations_souhaitees?.includes('Déclaration Préalable')}
        readOnly
        disabled
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="declaration_prealable" className="text-[#070707] font-semibold text-[16px]">
        Déclaration Préalable
      </label>
    </div>
    <div className="flex items-center mr-4">
      <input
        type="checkbox"
        id="enedis"
        name="prestations_souhaitees[]"
        value="ENEDIS"
        checked={demandeData.prestations_souhaitees?.includes('ENEDIS')}
        readOnly
        disabled
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="enedis" className="text-[#070707] font-semibold text-[16px]">
        ENEDIS
      </label>
    </div>
    <div className="flex items-center mr-4">
      <input
        type="checkbox"
        id="consuel"
        name="prestations_souhaitees[]"
        value="CONSUEL"
        checked={demandeData.prestations_souhaitees?.includes('CONSUEL')}
        readOnly
        disabled
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="consuel" className="text-[#070707] font-semibold text-[16px]">
        CONSUEL
      </label>
    </div>
    <div className="flex items-center">
      <input
        type="checkbox"
        id="autre"
        name="prestations_souhaitees[]"
        value="Autre(s)"
        checked={demandeData.prestations_souhaitees?.includes('Autre(s)')}
        readOnly
        disabled
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="autre" className="text-[#070707] font-semibold text-[16px]">
        Autre(s)
      </label>
      {showAutreInput && (
        <input
          type="text"
          name="autrePrecision"
          placeholder="Préciser"
          value={demandeData.autrePrecision || ''}
          readOnly
          className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
        />
      )}
    </div>
  </div>
</div>


       
            
          </form>
        )}

      {/* Common Sections */}
<div className="flex flex-col bg-white py-6 px-4 sm:px-6 md:px-12 lg:px-24 xl:px-24 2xl:px-24">
  <p className="self-start text-lg font-medium text-[#616565] md:text-2xl lg:text-3xl mx-4 w-full">
    Informations sur la nouvelle installation :
  </p>

  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
  <div className="flex items-center gap-2">
  <label htmlFor="surimposeISB" className="text-[#1c7573] font-medium text-[16px]">
    Surimposé/ISB
  </label>
  <input
    type="checkbox"
    id="surimposeISB"
    name="type_installation"
    value="Surimposé/ISB"
    checked={demandeData.type_installation === 'Surimposé/ISB'}
    disabled // Rendre la checkbox non modifiable
    className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
  />
</div>


<div className="flex items-center gap-2">
  <label htmlFor="integreAuBati" className="text-[#1c7573] font-medium text-[16px]">
    Intégré au bâti
  </label>
  <input
    type="checkbox"
    id="integreAuBati"
    name="type_installation"
    value="Intégré au bâti"
    checked={demandeData.type_installation === 'Intégré au bâti'}
    disabled // Rendre la checkbox non modifiable
    className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
  />
</div>


    <div className="flex items-center gap-1">
      <label htmlFor="puissance_prevue" className="w-full md:w-[100%] text-dark font-medium text-[15px]">
        Puissance prévue (kWc): *
      </label>
      <input
        type="number"
        id="puissance_prevue"
        name="puissance_prevue"
        placeholder="exp:10.2"
        value={demandeData.puissance_prevue}
        onChange={handleChange}
        readOnly
        className="w-full px-2 py-2 border border-gray-300 rounded-lg"
        required
      />
    </div>
  </div>

  <div className="grid grid-cols-1 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
  <div className="flex items-center gap-2">
    <label htmlFor="venteTotale" className="text-[#1c7573] font-medium text-[16px]">
      Vente totale
    </label>
    <input
      type="checkbox"
      id="venteTotale"
      name="type_production"
      value="Vente totale"
      checked={demandeData.type_production === 'Vente totale'}
      disabled // Rendre non modifiable
      className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
    />
  </div>
  <div className="flex items-center gap-2">
    <label htmlFor="autoconsommationVente" className="text-[#1c7573] font-medium text-[16px]">
      Autoconsommation + Vente du surplus
    </label>
    <input
      type="checkbox"
      id="autoconsommationVente"
      name="type_production"
      value="Autoconsommation + Vente du surplus"
      checked={demandeData.type_production === 'Autoconsommation + Vente du surplus'}
      disabled // Rendre non modifiable
      className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
    />
  </div>
  <div className="flex items-center gap-2">
    <label htmlFor="autoconsommationSeule" className="text-[#1c7573] font-medium text-[16px]">
      Autoconsommation seule
    </label>
    <input
      type="checkbox"
      id="autoconsommationSeule"
      name="type_production"
      value="Autoconsommation seule"
      checked={demandeData.type_production === 'Autoconsommation seule'}
      disabled // Rendre non modifiable
      className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
    />
  </div>
</div>


  <div className="mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
    <label htmlFor="marqueModele" className="block text-[#1c7573] font-medium text-[16px] mb-2">
      Nb marque et modèles panneaux et onduleurs(/batteries): *
    </label>
    <input
      type="text"
      id="marqueModele"
      name="marques_modeles_panneaux_onduleurs"
      value={demandeData.marques_modeles_panneaux_onduleurs}
      onChange={handleChange}
      readOnly
      className="w-full px-2 py-2 border border-gray-300 rounded-lg"
      required
    />
  </div>

  <div className="grid grid-cols-1 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
    <div>
      <label htmlFor="devis" className="text-[#1c7573] font-medium text-lg mb-2">
        Devis (matériel/fournisseurs) :
      </label>
      <textarea
        id="devis"
        name="devis"
        value={demandeData.devis}
        onChange={handleChange}
        readOnly
        className="w-full px-4 py-2 border border-gray-300 rounded-lg"
        rows={6}
        placeholder="Décrivez les détails du devis ici..."
        required
      />
    </div>
  </div>
</div>

{/* Destination du bâtiment */}
<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
  <label htmlFor="destination" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
    Destination du bâtiment: *
  </label>
  <div className="col-span-2 items-center grid grid-cols-1 2xl:grid-cols-3 xl:grid-cols-3">
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        id="destinationHabitation"
        name="destination_batiment"
        value="Habitation"
        checked={demandeData.destination_batiment === 'Habitation'}
        disabled // Rendre en lecture seule
        className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="destinationHabitation" className="font-normal text-[#1c7573]">
        Habitation
      </label>
    </div>

    {demandeData.type_client !== 'Particulier' && (
      <div className="flex items-center gap-2">
        <input
          type="checkbox"
          id="destinationSanteEnseignement"
          name="destination_batiment"
          value="Santé/enseignement"
          checked={demandeData.destination_batiment === 'Santé/enseignement'}
          disabled // Rendre en lecture seule
          className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
        />
        <label htmlFor="destinationSanteEnseignement" className="font-normal text-[#1c7573]">
          Santé/enseignement
        </label>
      </div>
    )}

    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        id="destinationAutre"
        name="destination_batiment"
        value="Autre"
        checked={demandeData.destination_batiment === 'Autre'}
        disabled // Rendre en lecture seule
        className="appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <label htmlFor="destinationAutre" className="font-normal text-[#1c7573]">
        Autre
      </label>
    </div>

    {demandeData.destination_batiment === 'Autre' && (
      <div className="mt-4">
        <label htmlFor="autreDestination" className="text-[#1c7573] font-medium mb-2">
          Précisez l'autre destination :
        </label>
        <input
          type="text"
          id="autreDestination"
          name="precisions_destination"
          value={demandeData.precisions_destination}
          readOnly // Lecture seule pour l'input texte
          className="col-span-2 px-2 py-2 border border-gray-300 rounded-lg"
        />
      </div>
    )}
  </div>
</div>

<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <label htmlFor="adresse" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
              Adresse:
            </label>
            <input
              type="text"
              name="adresse_production"
              id="adresse"
              value={demandeData.adresse_production || ''}
              readOnly
              className="col-span-2 px-2 py-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <label htmlFor="complementAdresse" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
              Complément d'adresse:
            </label>
            <input
              type="text"
              name="complement_adresse_production"
              id="complementAdresse"
              value={demandeData.complement_adresse_production || ''}
              readOnly
              className="col-span-2 px-2 py-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <label htmlFor="document_propriete" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
              Documents Propriété:
            </label>
            <input
              type="file"
              name="document_propriete"
              id="document_propriete"
              readOnly
              disabled
              className="col-span-2 px-2 py-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4 lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
            <div className="flex flex-col">
              <label htmlFor="cp" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
                CP:
              </label>
              <input
                type="number"
                id="cp"
                name="cp_production"
                value={demandeData.cp_production || ''}
                readOnly
                className="w-full px-2 py-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="flex flex-col lg:px-20 xl:px-20 2xl:px-20 md:px-20 sm:px-20">
              <label htmlFor="ville" className="text-[#1c7573] font-medium text-[16px] mb-2 md:mb-0">
                Ville: *
              </label>
              <input
                type="text"
                id="ville"
                name="ville_production"
                value={demandeData.ville_production || ''}
                readOnly
                className="w-full px-2 py-2 border border-gray-300 rounded-lg"
              />
            </div>
          </div>
          <h2 className="2xl:text-3xl text-[25px] font-medium text-[#616565]">Références cadastrales du terrain :</h2>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-0 px-2">
          <div className="flex flex-col lg:flex-row items-center gap-2">
            <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[30%] 2xl:text-end md:w-[100%]">
              N° de section :
            </label>
            <input
              type="number"
              name="ref_cadastrale_section"
              value={demandeData.ref_cadastrale_section || ''}
              onChange={handleChange}
              readOnly
              className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[50%] md:w-[100%]"
            />
          </div>
          <div className="flex flex-col lg:flex-row items-center gap-2">
            <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[30%] 2xl:text-end md:w-[100%]">
              N° de parcelle :
            </label>
            <input
              type="number"
              name="ref_cadastrale_parcelle"
              value={demandeData.ref_cadastrale_parcelle || ''}
              onChange={handleChange}
              readOnly
              className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[50%] md:w-[100%]"
            />
          </div>
          <div className="flex flex-col lg:flex-row items-center gap-2 xl:gap-1">
            <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[80%] 2xl:text-end">
              Surface totale :
            </label>
            <input
              type="number"
              name="surface_totale_parcelle"
              value={demandeData.surface_totale_parcelle || ''}
              onChange={handleChange}
              readOnly
              className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[60%]"
            />
            <label className="text-[#1c7573] font-medium text-[16px] w-full lg:w-[40%]">
              M<sup>2</sup>
            </label>
          </div>
        </div>

        <div className="flex flex-wrap sm:flex-row w-full mt-4">
          <label className="w-full text-[#1c7573] font-medium text-[16px] 2xl:w-[20%] xl:w-[20%] lg:w-[30%] md:w-[30%]">
            Coordonnées GPS ou Google Earth :
          </label>
          <input
            type="text"
            name="coordonnees_gps"
            value={demandeData.coordonnees_gps || ''}
            onChange={handleChange}
            readOnly
            className="px-2 py-2 border border-gray-300 rounded-lg w-full lg:w-[70%] md:w-[70%] 2xl:w-[80%] xl:w-[80%]"
          />
        </div>

        <div className="flex flex-col sm:flex-row items-baseline space-x-4 ml-5 mb-4">
  <label className="text-[#1c7573] font-medium">Branchement (compteur) :</label>

  <label className="flex items-center font-semibold text-[#1c7573] space-x-2">
    <span>Monophasé</span>
    <input
      type="checkbox"
      name="Monophasé"
      checked={demandeData.branchement_compteur === 'Monophasé'}
      onChange={handleChange}
      className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
    />
  </label>

  <label className="flex items-center font-semibold text-[#1c7573] space-x-2">
    <span>Triphasé</span>
    <input
      type="checkbox"
      name="Triphasé"
      checked={demandeData.branchement_compteur === 'Triphasé'}
      onChange={handleChange}
      className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
    />
  </label>
</div>

{/* Photovoltaic Installation Section */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-4 ml-5 mb-4">
  <div className="flex flex-col sm:flex-row items-left space-x-2">
    <label className="text-[#ff1c1e] italic font-medium">
      Disposez-vous déjà d'une installation photovoltaïque ?
    </label>
    <label className="flex items-center text-[#ff1c1e] italic space-x-2">
      <input
        type="radio"
        name="installation"
        value="non"
        checked={demandeData.installation_photovoltaique === 0}
        onChange={handleChange}
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <span>NON</span>
    </label>
    <label className="flex items-center text-[#ff1c1e] italic space-x-2">
      <input
        type="radio"
        name="installation"
        value="oui"
        checked={demandeData.installation_photovoltaique === 1}
        onChange={handleChange}
        className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
      />
      <span>OUI</span>
    </label>
  </div>
</div>

{/* If installation is "oui" */}
{demandeData.installation_photovoltaique === 1 && (
  <>
    {/* Nom Projet */}
    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5 mb-4">
      <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Nom de Chantier :</label>
      <input
        type="text"
        name="nom_projet"
        placeholder="Project-123456"
        value={demandeData.nom_projet || ''}
        readOnly
        onChange={handleChange}
        pattern="[a-zA-Z]+-\d{6}"
        required
        className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[50%]"
      />
    </div>
    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Nom Complet de CLient :*</label>
      <input
        type="text"
       name="nom"

       value={demandeData.nom|| ''}
    onChange={handleChange}
  required
  className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[50%]"
/>

    </div>
    {/* Date de MES */}
    <div className="flex flex-col sm:flex-row items-left space-x-1 italic font-medium ml-5 mb-4">
      <div className="flex flex-col sm:flex-row items-center space-x-1">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Date de MES :</label>
        <input
          type="date"
          name="date_mes"
          placeholder="A/M/J"
          value={demandeData.date_mes || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[40%]"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center space-x-1 w-full">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">N° de CRAE : 0000</label>
        <input
          type="number"
          name="numero_crae"
          readOnly
          value={demandeData.numero_crae || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[15%]"
        />
      </div>
    </div>
    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <div className="flex flex-col sm:flex-row items-center space-x-2">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Marque de l'ancien onduleur :</label>
        <input
          type="text"
          name="marque_ancien_onduleur"
          value={demandeData.marque_ancien_onduleur || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[43%]"
        />
      </div>
      <div className="flex flex-col sm:flex-row items-center space-x-2">
        <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">Modèle :</label>
        <input
          type="text"
          name="modele_ancien_onduleur"
          value={demandeData.modele_ancien_onduleur || ''}
          onChange={handleChange}
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[62%]"
        />
      </div>
    </div>

    <div className="flex flex-col sm:flex-row items-center space-x-2 italic font-medium ml-5">
      <label className="w-auto text-[#ff1c1e] font-medium text-[16px]">
        Puissance de l'ancienne installation de production (kWc) :
      </label>
      <input
        type="number"
        placeholder='exp:20.9'
        name="puissance_ancienne_installation"
        value={demandeData.puissance_ancienne_installation || ''}
        onChange={handleChange}
        className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[16%]"
      />
    </div>
  </>
)}

{/* If installation is "non" */}
{demandeData.installation_photovoltaique === 0 && (
  <>
    <div className="mb-4">
      <div className="flex flex-col md:flex-row items-left md:items-center">
        <h2 className="text-[24px] 2xl:text-3xl font-medium text-[#616565]">
          Informations et coordonnées complètes du client/producteur :
        </h2>
        <div className="mt-4 md:mt-0 md:ml-4 flex items-center">
          <input
            type="checkbox"
            name="autoconsommation"
            checked={demandeData.autoconsommation || false}
            onChange={handleChange}
            readOnly
            className="mr-2 appearance-none w-4 h-4 border-2 border-[#006462] checked:bg-[#006462]"
          />
          <label className="text-[#1c7573] font-medium text-[16px]">AUTOCONSOMMATION</label>
        </div>
      </div>
    </div>

    {/* Client Details */}
    <div className="grid grid-cols-1 gap-4 ml-5">
      <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
        <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom :*</label>
        <input
          type="text"
          name="nom"
          value={demandeData.nom || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
        />
        <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Prénom :*</label>
        <input
          type="text"
          name="prenom"
          value={demandeData.prenom || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
        />
      </div>
      
      {/* More Fields for Client */}
      <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
        <label className="w-auto text-[#1c7573] font-medium text-[16px]">Date de naissance :*</label>
        <input
          type="date"
          placeholder="A/M/J"
          name="date_de_naissance"
          value={demandeData.date_de_naissance || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[18%]"
        />
        <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Lieu de naissance :*</label>
        <input
          type="text"
          name="lieu_de_naissance"
          value={demandeData.lieu_de_naissance || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
        />
      </div>

      {/* Address Details */}
      <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
        <label className="w-auto text-[#1c7573] font-medium text-[16px]">Pays :*</label>
        <input
          type="text"
          name="pays"
          value={demandeData.pays || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-1/4"
        />
      </div>

      <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
        <label className="w-auto text-[#1c7573] font-medium text-[16px]">Adresse :*</label>
        <input
          type="text"
          name="adresse"
          value={demandeData.adresse || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[57%]"
        />
      </div>

      {/* Additional Fields */}
      <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
        <label className="w-auto text-[#1c7573] font-medium text-[16px]">Complément d'adresse :</label>
        <input
          type="text"
          name="complement_adresse"
          value={demandeData.complement_adresse || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[49.5%]"
        />
      </div>

      {/* Contact Information */}
      <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
        <label className="w-auto text-[#1c7573] font-medium text-[16px]">CP :*</label>
        <input
          type="number"
          name="cp"
          value={demandeData.cp || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[18%]"
        />
        <label className="w-auto text-[#1c7573] font-medium text-[16px] ml-4">Ville :*</label>
        <input
          type="text"
          name="ville"
          value={demandeData.ville || ''}
          onChange={handleChange}
          readOnly
          className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[45.5%]"
        />
      </div>

      {/* If autoconsommation is selected */}
      {demandeData.autoconsommation && (
        <>
          <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
            <label className="w-auto text-[#1c7573] font-medium text-[16px]">CIN :*</label>
            <input
              type="file"
              name="cin"
              id="cin"
              readOnly
              accept=".pdf, .jpg, .jpeg, .png, .doc, .docx"
              onChange={(e) => handleFileChange(e, 'cin')}
              className="w-full md:w-3/4 px-2 py-2 border border-gray-300 rounded-lg"
              required={demandeData.autoconsommation}
            />
          </div>
          <div className="flex flex-col sm:flex-row items-left space-x-1 gap-4">
            <label className="w-auto text-[#1c7573] font-medium text-[16px]">Nom du bénéficiaire :*</label>
            <input
              type="text"
              name="nom_beneficiaire"
              value={demandeData.nom_beneficiaire || ''}
              onChange={handleChange}
              readOnly
              className="px-2 py-2 border border-gray-300 rounded-lg w-full sm:w-[60%]"
              required={demandeData.autoconsommation}
            />
          </div>
        </>
      )}
   
    </div>
    
  </>
)}

<div className="flex justify-start ml-5">
  <button
    type="button"
    onClick={() => navigate(-1)} // Navigates back to the previous page
    className="w-[20%] py-2 sm:py-2 md:py-3 lg:py-3 xl:py-3 2xl:py-4 px-4 bg-[#006462] text-[#ffdf76] font-normal text-lg sm:text-lg md:text-xl lg:text-xl xl:text-xl 2xl:text-2xl rounded-3xl"
    style={{ borderRadius: '50px' }}
  >
    RETOUR
  </button>
</div>

      </div>
      


      <Footer />
    </div>
  );
};

export default DemandeDetail;
