// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import '../style/tailwind.css';
// import icon from '../img/icone-debuttexte.png';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import image from '../img/deco-bas de page.png'; 

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     console.log('Login successful');
//     navigate('/');
//   };

//   const handleButtonClick = () => {
//     navigate('/creation-demande');
//   };

//   return (
//     <div>
//       <Header />
     
//       <div className="flex flex-col items-center bg-white p-6 mx-8">
//         <p className="self-start mb-5 font-medium text-[#616565] text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
//           <img
//             src={icon}
//             alt="icon"
//             className="inline-block text-center w-6 h-6 align-middle mx-2"
//           />
//           Connectez-vous pour accéder à votre compte et demandes en cours :
//         </p>

//         <form onSubmit={handleSubmit} className="w-full max-w-xl space-y-6 p-4 sm:p-4 md:p-14 xl:p-14 2xl:p-14">
//           <div className="form-group">
//             <input
//               type="email"
//               id="email"
//               className="block w-full px-4 py-3 border border-2 rounded-lg mb-4"
//               placeholder="EMAIL"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </div>
//           <div className="form-group">
//             <input
//               type="password"
//               id="password"
//               className="block w-full px-4 py-3 border border-2 rounded-lg mb-4"
//               placeholder="MOT DE PASSE"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//             />
//           </div>
//           <div className="flex justify-center">
//             <button
//               type="submit"
//               className="w-[90%] py-3 sm:py-3 md:py-4 lg:py-4 xl:py-4 2xl:py-6 px-3 bg-[#006462] text-[#ffdf76] font-normal text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-4xl rounded-3xl"
//               style={{ borderRadius: '50px' }}
//             >
//               CONNEXION
//             </button>
//           </div>

//           <div className="text-center">
//             <label className="block mt-4 text-[#aeb1b5] font-normal flex items-center justify-center">
//               Mot de passe oublié ?{' '}
//               <a href="#" className="text-[#276e6f] font-normal underline ml-1">
//                 Cliquez ICI
//               </a>
//             </label>
//           </div>
//         </form>
//       </div>
//       <div className="flex relative flex-col items-center 2xl:min-h-[80vh] xl:min-h-[80vh] lg:min-h-[80vh] md:min-h-[80vh] sm:min-h-[47vh] min-h-[47vh]    bg-white p-6 mx-8">
//         <p className="self-start mb-5 font-medium text-[#616565] text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
//           <img
//             src={icon}
//             alt="icon"
//             className="inline-block text-center w-6 h-6 align-middle mx-2"
//           />
//           Vous n'avez pas encore de compte, créez une demande d'étude pour en ouvrir un :
//         </p>

//         <form onSubmit={handleSubmit} className="w-full max-w-xl space-y-4 p-4 sm:p-4 md:p-14 xl:p-14 2xl:p-14">
//         <div className="flex justify-center">
//             <button
//               type="submit"
//               className="w-[90%] py-3 sm:py-3 md:py-4 lg:py-4 xl:py-4 2xl:py-6 px-3 bg-[#006462] text-[#ffdf76] font-normal text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-4xl rounded-3xl"
//               style={{ borderRadius: '50px' }}
//               onClick={handleButtonClick}
//             >
//               NOUVELLE DEMANDE
//             </button>
//           </div>

//           <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-0 mt-8" >
//             <img src={image} alt="Image" className="w-auto h-auto" />
//           </div>
//         </form>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Login;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/tailwind.css';
import icon from '../img/icone-debuttexte.png';
import Header from '../components/Header';
import Footer from '../components/Footer';
import image from '../img/deco-bas de page.png'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // const response = await fetch('http://localhost:8000/api/login', {
        const response = await fetch('https://www.lampenergie.fr/admin/public/api/login', {

        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error data:', errorData);
        throw new Error(errorData.message || 'Login failed');
      }

      const data = await response.json();
      console.log('Login successful', data);
      
      // Handle successful login
      
      localStorage.setItem('token', data.token);
      navigate('/mon-compte');
    } catch (error) {
      console.error('Error during login:', error);
      setError(error.message || 'An error occurred. Please try again.');
    }
  };

  const handleButtonClick = () => {
    navigate('/mon-compte');
  };
  const handleButtoClick = () => {
    navigate('/creation-demande');
  };

  return (
    <div>
      <Header />

      <div className="flex flex-col items-center bg-white p-6 mx-8">
        <div className="self-start mb-5 font-medium text-[#616565] text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
          <img
            src={icon}
            alt="icon"
            className="inline-block text-center w-6 h-6 align-middle mx-2"
          />
          Connectez-vous pour accéder à votre compte et demandes en cours :
        </div>

        <form onSubmit={handleSubmit} className="w-full max-w-xl space-y-6 p-4 sm:p-4 md:p-14 xl:p-14 2xl:p-14">
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email" 
              className="block w-full px-4 py-3 border border-2 rounded-lg mb-4"
              placeholder="EMAIL"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              id="password"
              name="password" 
              className="block w-full px-4 py-3 border border-2 rounded-lg mb-4"
              placeholder="MOT DE PASSE"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && (
            <div className="text-red-500 text-center">
              {error}
            </div>
          )}
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-[90%] py-3 sm:py-3 md:py-4 lg:py-4 xl:py-4 2xl:py-6 px-3 bg-[#006462] text-[#ffdf76] font-normal text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-4xl rounded-3xl"
              style={{ borderRadius: '50px' }}
            >
              CONNEXION
            </button>
          </div>

          <div className="text-center mt-4">
            <label className="block text-[#aeb1b5] font-normal flex items-center justify-center">
              Mot de passe oublié ?{' '}
              <a href="#" className="text-[#276e6f] font-normal underline ml-1">
                Cliquez ICI
              </a>
            </label>
          </div>
        </form>
      </div>

      <div className="relative flex flex-col items-center bg-white p-6 mx-8 min-h-[47vh] sm:min-h-[47vh] md:min-h-[80vh] lg:min-h-[80vh] xl:min-h-[80vh] 2xl:min-h-[80vh]">
        <div className="self-start mb-5 font-medium text-[#616565] text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
          <img
            src={icon}
            alt="icon"
            className="inline-block text-center w-6 h-6 align-middle mx-2"
          />
          Vous n'avez pas encore de compte, créez une demande d'étude pour en ouvrir un :
        </div>

        <form className="w-full max-w-xl space-y-4 p-4 sm:p-4 md:p-14 xl:p-14 2xl:p-14">
          <div className="flex justify-center">
            <button
              type="button"
              className="w-[90%] py-3 sm:py-3 md:py-4 lg:py-4 xl:py-4 2xl:py-6 px-3 bg-[#006462] text-[#ffdf76] font-normal text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-4xl rounded-3xl"
              style={{ borderRadius: '50px' }}
              onClick={handleButtoClick}
            >
              NOUVELLE DEMANDE
            </button>
          </div>

          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-0 mt-8">
            <img src={image} alt="Image" className="w-auto h-auto" />
          </div>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default Login;
