import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom'; // Utiliser useParams pour obtenir le slug
import '../style/tailwind.css';
import icon from '../img/icone-debuttexte.png';
import Header from '../components/Header';
import Footer from '../components/Footer';
import image from '../img/deco-bas de page.png';

const ExamplePage = () => {
    const [pageContent, setPageContent] = useState('');  // État pour stocker le contenu de la page
    const { slug } = useParams();  // Récupérer le slug depuis l'URL
    const navigate = useNavigate();

    useEffect(() => {
        // Faire une requête pour récupérer les données en fonction du slug
        axios.get('http://www.lampenergie.fr/admin/public/api/info')
        .then(response => {
                // Vérifie que le contenu existe dans la réponse
                if (response.data && response.data.content) {
                    setPageContent(response.data.content);  // Stocker le contenu dans l'état
                } else {
                    console.error("Content not found in the response.");
                }
            })
            .catch(error => {
                console.error("There was an error fetching the content for this page!", error);
            });
    }, [slug]);  // Exécuter l'effet à chaque fois que le slug change

    // Fonction pour diviser le contenu en paragraphes de 5 lignes
    const formatContentToParagraphs = (content) => {
        const lines = content.split('\n'); // Suppose que chaque saut de ligne représente une nouvelle ligne
        const paragraphs = [];
        let currentParagraph = [];

        lines.forEach((line, index) => {
            currentParagraph.push(line); // Ajouter la ligne au paragraphe actuel
            // Vérifier si le paragraphe actuel a atteint 5 lignes
            if (currentParagraph.length === 5 || index === lines.length - 1) {
                paragraphs.push(currentParagraph.join('\n')); // Joindre les lignes en un paragraphe
                currentParagraph = []; // Réinitialiser le paragraphe actuel
            }
        });

        return paragraphs;
    };

    // Obtenir le contenu formaté
    const formattedContent = formatContentToParagraphs(pageContent);

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="relative container mx-auto px-4 py-8">
                <div>
                    <div className="flex items-center mb-24 ml-12">
                        <img src={icon} alt="icon" className="w-8 h-8 mr-3" />
                        <h1 className="text-3xl font-medium text-[#616565]">Informations & Tarifs :</h1>
                    </div>
                    <div className="mt-4 text-black text-[20px] font-semibold space-y-6 ml-24">
                        {/* Afficher le contenu dynamiquement avec des paragraphes */}
                        {formattedContent.map((paragraph, index) => (
                            <p key={index} className="mb-4">{paragraph}</p>
                        ))}
                    </div>
                </div>
                <div className="relative pt-48 bottom-1">
                    <img src={image} alt="Image" className="w-auto h-auto mx-auto" />
                </div>
            </main>
            <Footer />
        </div>
    );
};


export default ExamplePage;
