import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../img/logoweb.png';
import hamburger from '../img/hamburger.png';

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation(); // Obtenir le chemin actuel
  const [activeLink, setActiveLink] = useState(location.pathname); // Définir le lien actif basé sur l'URL

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
    setActiveLink(location.pathname); // Mettre à jour le lien actif à chaque changement de route
  }, [location.pathname]);

  const [isOpen, setIsOpen] = useState(false); // Gérer l'ouverture du menu pour mobile

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white border-gray-900 shadow-lg p-3 mb-5 rounded">
      <div className="flex flex-wrap items-center justify-between py-2 2xl:px-20 xl:px-20 lg:px-20">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img
            src={logo}
            className="h-10 sm:h-12 md:h-16 lg:h-20 xl:h-24 xl:w-80 2xl:h-20 2xl:w-80 md:w-64 lg:w-60"
            alt="Logo"
          />
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <img src={hamburger} className="w-5 h-5" alt="Menu" />
        </button>
        <div className={`${isOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
          <ul className="md:flex md:justify-center font-medium flex flex-col p-4 md:p-0 mt-4 border rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
            <li>
              <Link
                to="/"
                style={{
                  color: activeLink === '/' ? '#2e754f' : 'gray',
                  textDecoration: activeLink === '/' ? 'underline' : 'none'
                }}
                className="block py-2 px-3 text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded md:bg-transparent md:p-0"
              >
                Accueil
              </Link>
            </li>
            <ul>
              {isAuthenticated ? (
                <li>
                  <Link
                    to="/mon-compte"
                    style={{
                      color: activeLink === '/mon-compte' ? '#2e754f' : 'gray',
                      textDecoration: activeLink === '/mon-compte' ? 'underline' : 'none'
                    }}
                    className="block py-2 px-3 text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded md:bg-transparent md:p-0"
                  >
                    Mon Compte
                  </Link>
                </li>
              ) : (
                <li>
                  <Link
                    to="/installer-form-login"
                    style={{
                      color: activeLink === '/installer-form-login' ? '#2e754f' : 'gray',
                      textDecoration: activeLink === '/installer-form-login' ? 'underline' : 'none'
                    }}
                    className="block py-2 px-3 text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded md:bg-transparent md:p-0"
                  >
                    Connexion
                  </Link>
                </li>
              )}
            </ul>
            <li>
              <Link
                to="/info&tarifs"
                style={{
                  color: activeLink === '/info&tarifs' ? '#2e754f' : 'gray',
                  textDecoration: activeLink === '/info&tarifs' ? 'underline' : 'none'
                }}
                className="block py-2 px-3 text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded md:bg-transparent md:p-0"
              >
                Informations & Tarifs
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                style={{
                  color: activeLink === '/contact' ? '#2e754f' : 'gray',
                  textDecoration: activeLink === '/contact' ? 'underline' : 'none'
                }}
                className="block py-2 px-3 text-[16px] sm:text-[18px] md:text-[17px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] rounded md:bg-transparent md:p-0"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
